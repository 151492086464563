<template>
    <div class="loader-container">
        <img :src="getImgUrl(currentImage)" alt="Loader Image" class="loader-image" style="height: 75px;">
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            images: [
                'll1.png',
                'll2.png',
                'll3.png'

            ],
            currentImageIndex: 0,
            rotationInterval: null
        };
    },
    computed: {
        currentImage() {
            return this.images[this.currentImageIndex];
        }
    },
    mounted() {
        this.startRotation();
    },
    methods: {
        getImgUrl(img) {

            return require("../assets/" + img);

        },
        startRotation() {
            this.rotationInterval = setInterval(() => {
                this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
            }, 200); // Change rotation speed as needed (in milliseconds)
        },
        stopRotation() {
            clearInterval(this.rotationInterval);
        }
    },
    beforeDestroy() {
        this.stopRotation();
    }
};
</script>
  
<style>
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

</style>
  