<template>
  <div class="grid-dropdown-container">
 
    <div v-for="(i, x) in getbundles" :key="i.name" class="">
      <div class="">
        <div class="">
          <div
            @click="toggle(i.id)"
            class="new-dropdown full-width dropdown"
            :class="{ 'is-active': x == i.id }"
          >
            <div class="dropdown-trigger full-width" style="z-index: 24">
              <div
                class="box box-on-top more-rounded-box"
                aria-haspopup="true"
                aria-controls="dropdown-menu2"
              >
                <div class="columns">
                  <div class="column is-10" >
                    <div v-if="i.display_name" class="title is-5">
                      {{ i.display_name }}
                    </div>
                    <div v-else class="title is-5">{{ i.name }}</div>
                    <div class="subtitle is-6">
                      {{ i.workflows.length }} workflow |  {{ i.reports.length }} Reports
                    </div>
                  </div>
                  <div class="column">
                    <div class="on-end" style="height: 100%">
                      <div class="center">
                        <span v-if="isExpanded && currentId == i.id " class="icon-text">
                          
                          <span class="icon">
                            <img src="../assets/blue-cicle-filled-down.png" />
                          </span>
                        </span>
                        <span v-else class="icon-text">
                          <span class="icon">
                            <img src="../assets/blue-circle-down.png" />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="dropdown-menu dropdown-menu-custom full-width"
              id="dropdown-menu2"
              role="menu"
              v-show="isExpanded && x == currentId"
            >
           
              <div class="dropdown-content dropdown-contain-custom full-width">
                
                <div
                  v-for="(j, indx) in i.workflows"
                  :key="j.id"
                  class="dropdown-item"
                >
          
                  <div class="list-item">
                    <div class="list-item-content">
                      <div class="list-item-title">
                        <span v-if="j.display_name" >
                          {{ j.display_name }}
                        </span>
                        <span v-else>
                          {{ j.name }}
                        </span>
                       
                      </div>
                    </div>

                    <div class="list-item-controls">
                      <div class="field has-addons">
                        <p class="control is-right" style="margin: 0px"></p>
                        <p class="control" style="margin: 0px">
                          <div
                            @click="testing(j.name, j.display_name,'normal')"
                            class=""
                          >
                          <span v-if="isExpanded && currentId == i.id " class="icon-text">
                          
                              <span class="icon">
                                 <img src="../assets/plus-circle.png" />
                              </span>
                             </span>
                            </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div
                  v-for="(j, indx) in i.reports"
                  :key="j.id"
                  class="dropdown-item"
                >
          
                  <div class="list-item">
                    <div class="list-item-content">
                      <div class="list-item-title">
                        <span v-if="j.display_name" >
                          {{ j.display_name }}
                        </span>
                        
                        <span v-else>
                          {{ j.name }} 
                        </span>
                        <span class="tag is-info is-light">Report</span>
                      </div>
                    </div>

                    <div class="list-item-controls">
                      <div class="field has-addons">
                        <p class="control is-right" style="margin: 0px"></p>
                        <p class="control" style="margin: 0px">
                          <div
                            @click="testing(j.name, j.display_name ,'report')"
                            class=""
                          >
                          <span v-if="isExpanded && currentId == i.id " class="icon-text">
                          
                              <span class="icon">
                                 <img src="../assets/plus-circle.png" />
                              </span>
                             </span>
                            </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-for="i in getbundles" :key="i.name"  class="dropdown">
          <div class="dropdown-trigger">
            <button class="button"
            @click="toggle"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu">
              <span>{{ i.name }}</span>
              <span class="icon is-small">
                <i class="fas fa-angle-down"
                   aria-hidden="true"></i>
              </span>
            </button>
          </div>
 
          <div class="dropdown-menu"
               id="dropdown-menu"
               role="menu">
            <div  v-for="(j, indx) in i.workflows"
                  :key="j.id" class="dropdown-content">
              <a href="#" class="dropdown-item">
                    {{ j.display_name }}
              </a
            </div>
          </div>
        </div>
       -->
  </div>
</template>

<script>
export default {
  name: "boxDropdown",
  props: {
    getbundles: Array,
  },
  data() {
    return {
      isExpanded: false,
      selectedOptions: [],
      currentId: null,
      dropdowns: [
        {
          options: [
            { label: "Option 1.1", value: "option1.1" },
            { label: "Option 1.2", value: "option1.2" },
            { label: "Option 1.3", value: "option1.3" },
          ],
        },
        {
          options: [
            { label: "Option 2.1", value: "option2.1" },
            { label: "Option 2.2", value: "option2.2" },
            { label: "Option 2.3", value: "option2.3" },
          ],
        },
        {
          options: [
            { label: "Option 3.1", value: "option3.1" },
            { label: "Option 3.2", value: "option3.2" },
            { label: "Option 3.3", value: "option3.3" },
          ],
        },
      ],
    };
  },
  mounted() {
    const dropdown = document.querySelector(".dropdown");
    const active = document.querySelector(".is-active");
    document.body.addEventListener("click", function () {
      if (active) {
        dropdown.classList.remove("is-active");
      }
    });
    dropdown.addEventListener("click", function (event) {
      event.stopPropagation();
      this.classList.toggle("is-active");
    });
  },
  methods: {
    toggle(id) {
      //   this.$emit("toggle-dropdown");
      this.isExpanded = !this.isExpanded;
      this.currentId = id;
      //   const dropdown = document.querySelector(".dropdown");

      //   const active = document.querySelector(".is-active");
      //   console.log(dropdown.classList)
      //   document.body.addEventListener("click", function () {
      //     if (active) {
      //       dropdown.classList.remove("is-active");
      //     }
      //   });
      //   dropdown.addEventListener("click", function (event) {
      //     event.stopPropagation();
      //     this.classList.toggle("is-active");
      //   });
    },
    tog() {
      const dropdown = document.querySelector(".dropdown");
      const active = document.querySelector(".is-active");
      document.body.addEventListener("click", function () {
        if (active) {
          dropdown.classList.remove("is-active");
        }
      });
      // dropdown.addEventListener('click', function (event) {
      //   event.stopPropagation();
      //   this.classList. ('is-active');
      // });
    },
    testing: async function (value, display_name, doc_type) {
      this.$emit("display-create", {value, display_name, doc_type});
    },
  },
};
</script>
<style lang="scss" scoped>
.full-width {
  width: 100% !important;
}

.dropdown-menu-custom {
  display: none;
  left: 0;
  min-width: 12rem;
  padding-top: 0px !important;
  position: relative !important;
  top: 34% !important;
  z-index: 20;
}

.dropdown-contain-custom {
  padding-top: 43px !important;
  margin-bottom: 20px !important;
  border-bottom-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}

.new-dropdown {
  display: block !important;
}

.box-on-top {
  position: relative;
  z-index: 50;
}

.dropdown.is-active .dropdown-menu,
.dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
  margin-top: -43px !important;
}
</style>
