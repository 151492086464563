<template>
  <div class="top-container">
    <img class="home-background" src="../../public/1024x1024_sq_logo_grey1.png" />
    <div class="">
      <div class="" style="margin-bottom: 100px">
        <div v-if="filteredDataArray && !dispalyForm">
          <div class="">
            <nav class="navbar-white" role="navigation" aria-label="main navigation">
              <div id="navbarBasicExample" class="navbar-menu">
                <div class="navbar-start">
                  <div class="navbar-item" style="gap: 8px;">

                    <label class="switch">
                      <input type="checkbox" v-model="isCardView" />
                      <span class="round" :class="'slider-pink-purple'"></span>
                    </label>
                    <span> Change View</span>
                  </div>
                </div>

                <div class="navbar-end">
                  <div v-if="isCardView" class="center">
                    <div class="flex-center" v-show="isFilterEnable">
                      <div class="flex-center">
                        <span>Others</span>
                        <label class="switch" :disabled="filterBy == 'Elective' || filterBy == 'Complete'">
                          <input type="checkbox" v-model="filterMe"
                            :disabled="filterBy == 'Elective' || filterBy == 'Complete'" />
                          <span class="round" :class="'slider-orange'"></span>
                        </label>
                        <span>Me</span>
                      </div>
                      <b-field class="is-small b-rounded" rounded>
                        <b-radio-button v-model="filterBy" size="is-small" native-value="Complete" rounded>
                          Complete
                        </b-radio-button>
                        <b-radio-button v-model="filterBy" size="is-small" native-value="Elective">
                          Elective
                        </b-radio-button>
                        <b-radio-button v-model="filterBy" size="is-small" native-value="Pending">
                          <span>Pending</span>
                        </b-radio-button>
                        <b-radio-button v-model="filterBy" size="is-small" native-value="Urgent">
                          <span>Urgent</span>
                        </b-radio-button>
                      </b-field>
                    </div>

                    <div class="flex-center" style="padding: 0px 10px;">
                      <div>
                        <button class="button is-small is-rounded" @click="enableFilter()">
                          <span class="icon-text">
                            <span class="icon">
                              <img v-if="isFilterEnable" src="../assets/clear-filter.png">
                              <img v-else src="../assets/filter.png">
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <div class="height-main-container" style="display: flex;">
            <!-- Left sidebar -->

            <div v-if="!isCardView" class="left-sidebar">
              <div class="pending-background">
                <div class="horzontal-element">
                  <div class="icon">
                    <QbIcon :notooltip="false" svgFile="warning-orange.svg"></QbIcon>
                  </div>
                  <div class="pending-actions">Pending Actions</div>
                </div>
              
                <div v-for="(item, id) in filteredDataArray" :key="id" class="pending-list"  >
                  <div class="line-12"></div>
                  <!-- {{ id }} {{ infoData }} {{ item }} -->
                  <div class="pending-item">
                    <div   @click="onSelect(item.id, item.name, id)" :class="{ pendingdiscrption:true, active: id == infoData.cardid && infoData.processType == 'pending'}" style="padding: 7px 10px;">
                      {{ item.description }}
                    </div>
                    <div>
                      <div class="horzontal-element">
                        <div class="center">
                          <span class="icon">
                            <img src="../assets/timer-org.png" style="height: 20px;" />
                          </span>
                        </div>
                        <div class="pending-actions center " style="font-size: 14px;">{{ item.expires | remaining_time_precise}}</div>
                        <div class="on-end" style="width: 100%;">
                          <!-- <button class="button is-newaccent extraSmall is-rounded"
                            @click="onSelect(item.id, item.name, id)">Select</button> -->
                            <button @click="openForm(item.id, id , null ,'pending')"
                            class="button extraSmall is-newaccent is-rounded">
                            Launch
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="filteredDataArray.length == 0" style="padding: 10px;"> 
                  All Caught-up, You don't have any pending tasks ! 
                </div>
              </div>
              <div>
                <newDropdown @onLunch="openForm($event.id, $event.indexid ,$event.toggleid ,$event.processType)" 
                @process_selected="onSelectDormat($event)" 
                :getbundles="dormantProcess" 
                :istoggedfor="gutterToggleId" 
                :processType="infoData.processType"
                :selectedForm="currentSelectedFormID"></newDropdown>
              </div>
            </div>
            <!-- {{ filteredDataArray }} -->
            <!-- This is main grid which contains all cards -->
            <div v-if="isCardView" class="just-width-100">
              <constgrid :displayType="displayType" :alldata="filteredDataArray" :isFilterEnable="isFilterEnable"
                :filterBy="filterBy" :filterMe="filterMe" :noOfColumns="6" :cellWith="2" :cellHeight="4"
                :itemFileType="'justFile'" sectionType="pending"></constgrid>
            </div>
            <div v-else class="just-width-100 details-container">
              <div v-show="!detailsOnSelect" class="">
                <div class="">
                  <div class="background-home-prompt">
                    <p class="welcome-user">
                      Welcome {{ currentuserDetails ? currentuserDetails.profile.full_name : "" }}!
                    </p>
                    <p class="home-page-promt">
                      "Drive your document processes with Qbrics Flows. Avail blockchain based compliance."
                    </p>
                  </div>
                </div>

                <div class="">
                  <div class="" style="margin: 10px;">
                    <div class="column box-rounded">
                     
                      <div class="columns">
                        <div class="column">
                          <div class="center">
                            <div class="center count-box box-vr-gradiant-blue has-text-centered">
                              <span class="pending-stats">
                                {{ currentStats ? currentStats.num_urgent : "NA"}}
                              </span>
                            </div>
                          </div>
                          <div class="center">
                            <span class="font-details"> Urgent Tasks </span>
                          </div>
                        </div>
                        <div class="column">
                          <div class="center">
                            <div class="center count-box box-grad-down-teal has-text-centered">
                              <span class="pending-stats">
                                {{ currentStats ? currentStats.num_dormant : "NA"}}
                              </span>
                            </div>
                          </div>
                          <div class="center">
                            <span class="font-details"> Dormant Tasks </span>
                          </div>
                        </div>
                        <div class="column">
                          <div class="center">
                            <div class="center count-box box-grad-down-red has-text-centered">
                              <span class="pending-stats">
                                {{ currentStats ? currentStats.num_complete : "NA" }}
                              </span>
                            </div>
                          </div>
                          <div class="center">
                            <span class="font-details"> Completed Tasks </span>
                          </div>
                        </div>
                        <div class="column">
                          <div class="center">
                            <div class="center count-box box-grad-down-green has-text-centered">
                              <span class="pending-stats">
                                {{ currentStats ? currentStats.num_launched : "NA"}}
                              </span>
                            </div>
                          </div>
                          <div class="center">
                            <span class="font-details"> Launched Tasks </span>
                          </div>
                        </div>
                        <div class="column">
                          <div class="center">
                            <div class="center count-box box-grad-down-green has-text-centered">
                              <span class="pending-stats">
                                {{ currentStats ? currentStats.wallet_balance : "NA" }}
                              </span>
                            </div>
                          </div>
                          <div class="center">
                            <span class="font-details"> Wallet Balance </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="center">
                    <span style="font-size: 20px;">
                      Select a process!
                    </span>
                  </div>
                  <div v-if="enableLoader && detailsOnSelect">
                    <qbloader></qbloader>
                  </div>
                  <div v-if="displayImg" class="">
                    <div class="center"> 
                      <span class="">
                        <img src="../assets/selectprocess.png" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="infoData.workflowId && detailsOnSelect">
             
                <div class="">

                  <nav class="qb-nav navbar" role="navigation" aria-label="main navigation just-box-rounded-top">
                    <div id="" class="navbar-menu">
                      <div class="navbar-start">
                        <div class="navbar-item subtitle">
                          {{ infoData.display_name }}
                        </div>
                      </div>

                      <div class="navbar-end">
                        <div class="navbar-item">
                          <button @click="openForm(infoData.workflowId, infoData.cardid)"
                            class="button is-small is-newaccent very-slight-rounded">
                            Launch
                          </button>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>


                <div class="container">
                  <div class="card-container">
                    <div class="">
                      <div class="tab-content-position">
                        <div class="tab-pane" id="v1-info">
                          <div class="">
                            <div class="">
                              <div class="">
                                <div class="box">
                                  <div class="card-header">
                                    <div class="card-header-title">
                                      <b-taglist attached>
                                        <b-tag type="is-rajivsgreen">WorkFlow ID</b-tag>

                                        <b-tag :closable="actualWorkFlowID !== ''" close-type="is-danger"
                                          @close="clearWorkFlow()" type="is-dark">{{
                                            infoData.workflowId || "Not Available"
                                          }}</b-tag>
                                      </b-taglist>
                                    </div>
                                  </div>
                                  <div class="card-content">
                                    <div class="columns">
                                      <div class="column is-3">
                                        <p class="has-text-info is-size-6 has-text-weight-semibold">
                                          WorkFlow State:
                                        </p>
                                      </div>
                                      <div class="column">
                                        <p class="is-size-6">
                                          {{
                                            infoData.currentState || "Not Available"
                                          }}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="columns">
                                      <div class="column is-3">
                                        <p class="has-text-info is-size-6 has-text-weight-semibold">
                                          WorkFlow Initiated By:
                                        </p>
                                      </div>
                                      <div class="column">
                                        <p v-if="infoData.meta" class="is-size-6">
                                          {{
                                            infoData.meta.initiator || "Not Available"
                                          }}
                                        </p>
                                        <p v-else>Not Available</p>
                                      </div>
                                    </div>
                                    <div class="columns">
                                      <div class="column is-3">
                                        <p class="has-text-info is-size-6 has-text-weight-semibold">
                                          Your Role(s):
                                        </p>
                                      </div>
                                      <div class="column">
                                        <p v-for="i in infoData.roles" class="is-size-6">
                                          {{ i || "Not Available" }}
                                        </p>
                                        <p v-if="!infoData.roles">Not Available</p>
                                      </div>
                                    </div>
                                    <div class="columns">
                                      <div class="column is-3">
                                        <p class="has-text-info is-size-6 has-text-weight-semibold">
                                          Last Transaction:
                                        </p>
                                      </div>
                                      <div class="column">
                                        <p v-if="infoData.lastTransaction" class="is-size-6">
                                          {{
                                            infoData.lastTransaction.txid ||
                                            "Not Available"
                                          }}
                                        </p>
                                        <p v-else>Not Available</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="v1-report">
                          <div v-if="infoData.query" style="margin-bottom: 100px">
                            <DataTable v-if="infoData.query.length > 0" :value="infoData.query" ref="reportdt"
                              responsiveLayout="scroll" :filters.sync="filtersReport" filterDisplay="menu"
                              :globalFilterFields="uniqueKey">
                              <template #header>
                                <nav class="navbar" role="navigation" aria-label="main navigation"
                                  style="background-color: hsl(0deg 0% 0% / 0%)">
                                  <div id="navbarBasicExample" class="navbar-menu">
                                    <div class="navbar-start">
                                      <div class="navbar-item">
                                        <span>
                                          <button class="button is-rounded is-small" @click="exportCSV($event)">
                                            <span>Export to CSV</span>
                                          </button>
                                        </span>
                                      </div>
                                    </div>

                                    <div class="navbar-end">
                                      <div class="navbar-item">
                                        <div>
                                          <span class="p-input-icon-left">
                                            <!-- <span>  <i class="fas fa-search" /></span> -->

                                            <span v-if="uniqueKey.includes('employee')">
                                              <InputText class="input is-small is-rounded" v-model="filtersReport['employee'].value
                                                " placeholder="Employee" />
                                            </span>

                                            <span v-if="uniqueKey.includes('user')">
                                              <InputText v-model="filtersReport['user'].value" placeholder="User" />
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="navbar-item">
                                        <button label="Clear" class="button is-small is-white" @click="clearFilter()">
                                          <span class="icon is-small">
                                            <span class="icon">
                                              <img src="../assets/filter.png">
                                            </span>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </nav>
                                <div class="flex justify-content-between" style="text-align: left">
                                  <span> </span>
                                </div>
                              </template>
                              <Column v-for="col of colArr" :field="col.field" :header="col.header" :key="col.key"
                                :filterField="col.field" sortable>
                                <template v-if="col.field == 'compliance'" #body="{ data }">
                                  <span :class="'tag ' + setStatusClass(data.compliance)">{{ data.compliance }}</span>
                                </template>
                              </Column>
                            </DataTable>
                            <div v-else>
                              <div v-if="infoData.workflowId === ''" class="title is-6 has-text-centered">
                                No process selected
                              </div>
                              <div v-else class="title is-6 has-text-centered">
                                No reports to show
                              </div>
                            </div>
                          </div>
                          <div v-else class="">
                            <div>
                              <div v-if="infoData.workflowId === ''" class="title is-6 has-text-centered">
                                No process selected
                              </div>
                              <div v-else class="title is-6 has-text-centered">
                                No reports to show
                              </div>
                            </div>
                            <div class="center">
                              <figure class="image custom-img-container figure-disp">
                                <img class=""
                                  src="../../public/vecteezy_web-application-developer-and-data-analysis_-removebg-preview.png" />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="v1-history">
                          <div class="">
                            <div v-if="txns.length == 0" class="center">
                              <div>
                                <div v-if="infoData.workflowId === ''" class="title is-6 has-text-centered">
                                  Please select a workflow or create one
                                </div>
                                <div v-else class="title is-6 has-text-centered">
                                  No transactions to show
                                </div>
                                <figure class="image custom-img-container figure-disp">
                                  <img class=""
                                    src="../../public/vecteezy_web-application-developer-and-data-analysis_-removebg-preview.png" />
                                </figure>
                              </div>
                            </div>

                            <div v-else-if="txns.length > 0">
                              <history :transactions="txns"></history>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="v1-help">
                          <div class="markdown-html" v-html="htmloutput" style="padding-bottom: 95px;"></div>
                        </div>
                        <div class="tab-pane is-active" id="v1-detials">
                          
                          <div v-if="workflowDetails">
                            <detailsTab :details="workflowDetails" :info="infoData" :filteredinfo="filteredInfo"
                              :formName="$store.getters.currentFormName"></detailsTab>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tabs-v1 tabs-container">
                  <ul>
                    <li class="is-active">
                      <a id="v1-detials">
                        <span class="icon is-small"><i class="fa-solid fa-asterisk"></i></span>
                        <span>Details</span>
                      </a>
                    </li>

                    <li>
                      <a id="v1-report">
                        <span class="icon is-small"><i class="fa-solid fa-file-invoice"></i>
                        </span>
                        <span>Report</span>
                      </a>
                    </li>
                    <li>
                      <a id="v1-history">
                        <span class="icon is-small"><i class="fa fa-clock-rotate-left"></i></span>
                        <span>History</span>
                      </a>
                    </li>

                    <li>
                      <a id="v1-help">
                        <span class="icon is-small"><i class="fa-solid fa-question"></i></span>
                        <span>Help</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>


            </div>
          </div>
        </div>
        <!-- This is the popup for i9 and other forms -->
        <b-modal v-model="dispalyForm" width="87%">
          <div class="popup-box just-box-rounded">
            <nav class="navbar" role="navigation" aria-label="main navigation">
              <div id="navbarBasicExample" class="navbar-menu">
                <div class="navbar-start">
                  <div class="navbar-item">
                    <button @click="dispalyForm = false" class="button is-rounded is-small">
                      <span class="icon is-small">
                        <i class="fa-solid fa-chevron-left"></i>
                      </span>
                    </button>
                  </div>
                </div>

                <div class="navbar-end">
                  <div class="navbar-item"></div>
                </div>
              </div>
            </nav>


            <!-- this is actual form -->
            
            <div v-if="isReportType" >
                <reports></reports>
            </div>
            <div v-else>
              <signpopup></signpopup>
            </div>
          </div>
        </b-modal>

      </div>
      <!-- This popoup is for the workflow details -->
      <b-modal v-model="displayData">
        <div class="popup-box just-box-rounded">
          <div class="box popup-header just-box-rounded">

            <nav class="navbar" role="navigation" aria-label="main navigation">
              <div id="" class="navbar-menu">
                <div class="navbar-start">
                  <div class="navbar-item subtitle">
                    {{ infoData.display_name }}
                  </div>
                </div>

                <div class="navbar-end">
                  <div class="navbar-item">
                    <button @click="openForm(infoData.workflowId, infoData.cardid)"
                      class="button is-small is-newaccent very-slight-rounded">
                      Launch
                    </button>
                  </div>
                </div>
              </div>
            </nav>
          </div>

          <div class="container">
            <div class="card-container">
              <div class="">
                <div class="tab-content tab-content-position">
                  <div class="tab-pane" id="info">
                    <div class="">
                      <div class="">
                        <div class="">
                          <div class="box">
                            <div class="card-header">
                              <div class="card-header-title">
                                <b-taglist attached>
                                  <b-tag type="is-rajivsgreen">WorkFlow ID</b-tag>

                                  <b-tag :closable="actualWorkFlowID !== ''" close-type="is-danger"
                                    @close="clearWorkFlow()" type="is-dark">{{
                                      infoData.workflowId || "Not Available"
                                    }}</b-tag>
                                </b-taglist>
                              </div>
                            </div>
                            <div class="card-content">
                              <div class="columns">
                                <div class="column is-3">
                                  <p class="has-text-info is-size-6 has-text-weight-semibold">
                                    WorkFlow State:
                                  </p>
                                </div>
                                <div class="column">
                                  <p class="is-size-6">
                                    {{
                                      infoData.currentState || "Not Available"
                                    }}
                                  </p>
                                </div>
                              </div>
                              <div class="columns">
                                <div class="column is-3">
                                  <p class="has-text-info is-size-6 has-text-weight-semibold">
                                    WorkFlow Initiated By:
                                  </p>
                                </div>
                                <div class="column">
                                  <p v-if="infoData.meta" class="is-size-6">
                                    {{
                                      infoData.meta.initiator || "Not Available"
                                    }}
                                  </p>
                                  <p v-else>Not Available</p>
                                </div>
                              </div>
                              <div class="columns">
                                <div class="column is-3">
                                  <p class="has-text-info is-size-6 has-text-weight-semibold">
                                    Your Role(s):
                                  </p>
                                </div>
                                <div class="column">
                                  <p v-for="i in infoData.roles" class="is-size-6">
                                    {{ i || "Not Available" }}
                                  </p>
                                  <p v-if="!infoData.roles">Not Available</p>
                                </div>
                              </div>
                              <div class="columns">
                                <div class="column is-3">
                                  <p class="has-text-info is-size-6 has-text-weight-semibold">
                                    Last Transaction:
                                  </p>
                                </div>
                                <div class="column">
                                  <p v-if="infoData.lastTransaction" class="is-size-6">
                                    {{
                                      infoData.lastTransaction.txid ||
                                      "Not Available"
                                    }}
                                  </p>
                                  <p v-else>Not Available</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="report">
                    <div v-if="infoData.query" style="margin-bottom: 100px">
                      <DataTable v-if="infoData.query.length > 0" :value="infoData.query" ref="reportdt"
                        responsiveLayout="scroll" :filters.sync="filtersReport" filterDisplay="menu"
                        :globalFilterFields="uniqueKey">
                        <template #header>
                          <nav class="navbar" role="navigation" aria-label="main navigation"
                            style="background-color: hsl(0deg 0% 0% / 0%)">
                            <div id="navbarBasicExample" class="navbar-menu">
                              <div class="navbar-start">
                                <div class="navbar-item">
                                  <span>
                                    <button class="button is-rounded is-small" @click="exportCSV($event)">
                                      <span>Export to CSV</span>
                                    </button>
                                  </span>
                                </div>
                              </div>

                              <div class="navbar-end">
                                <div class="navbar-item">
                                  <div>
                                    <span class="p-input-icon-left">
                                      <!-- <span>  <i class="fas fa-search" /></span> -->

                                      <span v-if="uniqueKey.includes('employee')">
                                        <InputText class="input is-small is-rounded" v-model="filtersReport['employee'].value
                                          " placeholder="Employee" />
                                      </span>

                                      <span v-if="uniqueKey.includes('user')">
                                        <InputText v-model="filtersReport['user'].value" placeholder="User" />
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div class="navbar-item">
                                  <button label="Clear" class="button is-small is-white" @click="clearFilter()">
                                    <span class="icon is-small">
                                      <span class="icon">
                                        <img src="../assets/filter.png">
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </nav>
                          <div class="flex justify-content-between" style="text-align: left">
                            <span> </span>
                          </div>
                        </template>
                        <Column v-for="col of colArr" :field="col.field" :header="col.header" :key="col.key"
                          :filterField="col.field" sortable>
                          <template v-if="col.field == 'compliance'" #body="{ data }">
                            <span :class="'tag ' + setStatusClass(data.compliance)">{{ data.compliance }}</span>
                          </template>
                        </Column>
                      </DataTable>
                      <div v-else>
                        <div v-if="infoData.workflowId === ''" class="title is-6 has-text-centered">
                          No process selected
                        </div>
                        <div v-else class="title is-6 has-text-centered">
                          No reports to show
                        </div>
                      </div>
                    </div>
                    <div v-else class="">
                      <div>
                        <div v-if="infoData.workflowId === ''" class="title is-6 has-text-centered">
                          No process selected
                        </div>
                        <div v-else class="title is-6 has-text-centered">
                          No reports to show
                        </div>
                      </div>
                      <div class="center">
                        <figure class="image custom-img-container figure-disp">
                          <img class=""
                            src="../../public/vecteezy_web-application-developer-and-data-analysis_-removebg-preview.png" />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="history">
                    <div class="">
                      <div v-if="txns.length == 0" class="center">
                        <div>
                          <div v-if="infoData.workflowId === ''" class="title is-6 has-text-centered">
                            Please select a workflow or create one
                          </div>
                          <div v-else class="title is-6 has-text-centered">
                            No transactions to show
                          </div>
                          <figure class="image custom-img-container figure-disp">
                            <img class=""
                              src="../../public/vecteezy_web-application-developer-and-data-analysis_-removebg-preview.png" />
                          </figure>
                        </div>
                      </div>

                      <div v-else-if="txns.length > 0">
                        <!-- <DataTable
                            :value="txns"
                            stripedRows
                            selectionMode="single"
                            responsiveLayout="scroll"
                          >
                            <Column field="state" header="State"></Column>
                            <Column field="user" header="Person"></Column>
                            <Column field="action" header="Action"></Column>
                            <Column field="time" header="Time"></Column>
                            <Column
                              field="txid"
                              header="Transaction ID"
                            ></Column>
                          </DataTable> -->
                        <history :transactions="txns"></history>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="help">
                    <div class="markdown-html" v-html="htmloutput" style="padding-bottom: 95px;"></div>
                  </div>
                  <div class="tab-pane is-active" id="detials">
                    <div v-if="workflowDetails">
                      <!-- <div class="card-content">
                          <div class="columns">
                            <div class="column is-3">
                              <p
                                class="has-text-info is-size-6 has-text-weight-semibold"
                              >
                                WorkFlow Name:
                              </p>
                            </div>
                            <div class="column">
                              <p class="is-size-6">
                                {{
                                  workflowDetails.data.name || "Not Available"
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column is-3">
                              <p
                                class="has-text-info is-size-6 has-text-weight-semibold"
                              >
                                WorkFlow Description:
                              </p>
                            </div>
                            <div class="column">
                              <p v-if="infoData.meta" class="is-size-6">
                                {{
                                  workflowDetails.data.description ||
                                  "Not Available"
                                }}
                              </p>
                              <p v-else>Not Available</p>
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column is-3">
                              <p
                                class="has-text-info is-size-6 has-text-weight-semibold"
                              >
                                Available Role(s):
                              </p>
                            </div>
                            <div class="column">
                              <p class="is-size-6">
                                <span
                                  v-for="i in workflowDetails.data.roles"
                                  class="tag is-info is-light"
                                  style="margin-right: 10px"
                                  >{{ i }}</span
                                >
                              </p>
                              <p v-if="!infoData.roles">No Roles!</p>
                            </div>
                          </div>
                        </div> -->
                      <!-- {{workflowDetails.data}}-----------{{infoData}} ------ {{filteredInfo}} -->
                      <detailsTab :details="workflowDetails" :info="infoData" :filteredinfo="filteredInfo"
                        :formName="$store.getters.currentFormName"></detailsTab>
                    </div>
                    <!-- <div v-else class="">
                        <div>
                          <div class="title is-6 has-text-centered">
                            No proccess selected
                          </div>
                        </div>
                        <div class="center">
                          <figure
                            class="image custom-img-container figure-disp"
                          >
                            <img
                              class=""
                              src="../../public/vecteezy_web-application-developer-and-data-analysis_-removebg-preview.png"
                            />
                          </figure>
                        </div>
                      </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs tabs-container">
            <ul>
              <li class="is-active">
                <a id="detials">
                  <span class="icon is-small"><i class="fa-solid fa-asterisk"></i></span>
                  <span>Details</span>
                </a>
              </li>

              <li>
                <a id="report">
                  <span class="icon is-small"><i class="fa-solid fa-file-invoice"></i>
                  </span>
                  <span>Report</span>
                </a>
              </li>
              <li>
                <a id="history">
                  <span class="icon is-small"><i class="fa fa-clock-rotate-left"></i></span>
                  <span>History</span>
                </a>
              </li>

              <li>
                <a id="help">
                  <span class="icon is-small"><i class="fa-solid fa-question"></i></span>
                  <span>Help</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </b-modal>
      <!-- This popup is for create new workflow -->
      <b-modal v-model="createNewWorkflow" class="">
        <div class="just-white rounded-box-container">
          <div class="">
            <div class="just-box just-shadow-buttom just-box-rounded-top">
              <div class="">
                <div class="">
                  <nav class="navbar-transparent" style="background-color: none !important" role="navigation"
                    aria-label="main navigation">
                    <div id="navbarBasicExample" class="navbar-menu">
                      <div class="navbar-start">
                        <div class="navbar-item">
                          <button @click="backButtonHandler()" class="button is-rounded">
                            <span class="icon is-small">
                              <i class="fa-solid fa-chevron-left"></i>
                            </span>
                          </button>
                        </div>
                        <div v-show="!showBulkupload && !showCreateInstace" class="navbar-item">
                          <b-autocomplete rounded v-model="searchName" placeholder="e.g. I9 Form for User" icon-pack="fas"
                            icon="search" group-field="name" group-options="workflows" :data="filteredDataObj"
                            :keep-first="true" :open-on-focus="true" field="display_name"
                            @select="(option) => (selected = option)" :clearable="true">
                            <template #empty>No results found</template>
                          </b-autocomplete>
                        </div>
                      </div>
                      <div class="navbar-end">
                        <div v-show="!showBulkupload && showCreateInstace" class="navbar-item">
                          <button class="button  is-lightblue is-rounded" :disabled="!$store.getters.getUserDetails.org_account
                            " @click="showbulkupload">
                            <span class="icon  buttom-icon-container">
                              <img src="../assets/icon_svg/Group.png" />
                            </span>
                            <span>Bulk Upload</span>
                          </button>
                        </div>
                        <div v-show="showBulkupload" class="navbar-item">
                          <button class="button  is-lightblue is-rounded" :disabled="!$store.getters.getUserDetails.org_account
                            " @click="showBulkupload = false">
                            <span class="icon  buttom-icon-container">
                              <img src="../assets/left-circle.png" />
                            </span>
                            <span>Create</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <div v-if="!showCreateInstace && !showBulkupload" class="popup-container">

              <boxDropdown @display-create="testing($event.value, $event.display_name, $event.doc_type)" :getbundles="filteredDataObj"></boxDropdown>
            </div>

            <!-- <Accordion :activeIndex="0">
            <AccordionTab
              v-for="i in getBundles"
              :header="i.name"
              :key="i.name"
            >
              <div
                v-for="(j, indx) in i.workflows"
                :key="j.id"
                class="list has-visible-pointer-controls"
              >
                <div class="list-item">
                  <div class="list-item-image">
                    <a :href="'/api/template/' + j.name">
                      <figure class="image is-64x64">
                        <img
                          src="../assets/Custom_dimensions_512x512_px-removebg-preview.png"
                        />
                      </figure>
                    </a>
                  </div>

                  <div class="list-item-content">
                    <div class="list-item-title">{{ j.display_name }}</div>
                  </div>

                  <div class="list-item-controls">
                    <div class="field has-addons">
                      <p class="control is-right" style="margin: 0px"></p>
                      <p class="control" style="margin: 0px">
                        <button
                          @click="testing(j.name, j.display_name)"
                          class="button"
                        >
                          <span class="icon is-small">
                            <i class="fa-solid fa-chevron-right"></i>
                          </span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion> -->
            <!-- <Accordion :activeIndex="1">
            <AccordionTab :header="'Others'" :key="'Others'">
              <div
                v-for="j in $store.getters.availables"
                class="list has-visible-pointer-controls"
              >
                <div class="list-item">
                  <div class="list-item-image">
                    <a>
                      <figure class="image is-64x64">
                        <img
                          src="../assets/Custom_dimensions_512x512_px-removebg-preview.png"
                        />
                      </figure>
                    </a>
                  </div>

                  <div class="list-item-content">
                    <div class="list-item-title">{{ j.display_name }}</div>
                  </div>

                  <div class="list-item-controls">
                    <div class="field has-addons">
                      <p class="control is-right" style="margin: 0px"></p>
                      <p class="control" style="margin: 0px">
                        <button
                          @click="testing(j.name, j.display_name)"
                          class="button"
                        >
                          <span class="icon is-small">
                            <i class="fa-solid fa-chevron-right"></i>
                          </span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion> -->
          </div>
          <div v-if="showCreateInstace" class="just-box">
            <div class="popup-container">
              <form @submit.prevent="">
                <div class="">
                  <div v-if="docType == 'normal'" class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Add users for</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <Dropdown class="more-rounded-box" @input="updateRoleSate(currentRoleSeleted)"
                          v-model="currentRoleSeleted" :options="instaceMeta.roles" placeholder="Select a Role" />
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">User(s)</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <!-- <p class=" more-rounded-box control has-icons-right">
                        <Chips
                        class="more-rounded-box"
                          @input="updatevalue(currentRoleSeleted,$event)"
                          v-model="createInstance.users"
                          separator=","
                        /> 
                        <span class="button-in-input is-small is-right">
                          <button class="button is-rounded is-small" @click="updatevalue(currentRoleSeleted)" >Add  </button>
                         </span>

                        <input id="input-field" v-model="userString" @keyup.enter="addToArr(currentRoleSeleted)">
                      </p> -->
                        <div class="control">
                          <input id="input-field" class="input is-rounded" v-model="userString" />

                          <span class="button-in-input is-small is-right">
                            <button class="button is-rounded is-small" @click="addToArr(currentRoleSeleted)">
                              Add
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label"></label>
                    </div>
                    <div class="field-body">
                      <div class="field just-box-rounded">
                        <div class="more-rounded-box control">
                          <div class="tagsfield field is-grouped is-grouped-multiline">
                            <div>
                              <Chips class="just-box-rounded" v-model="createInstance.users" separator="," /> 
                              <!-- <textarea class="textarea  just-rounded-box" placeholder="e.g. Hello world"  type="hidden" :value="createInstance.users"></textarea> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div>
              <!-- <div class="columns">
              <div class="column has-text-left has-text-danger">
                <p>
                  Note:<br />
                  Hit enter to add user, multiple users can be saperated with
                  comma.<br />Every role defined in the workflow must have
                  atleast one user or use bulk upload to create in bulk.<br /><span
                    class="has-text-black"
                  >
                    Download template for
                    <a
                      class="is-link"
                      :href="'/api/template/' + instaceMeta.name"
                      >{{ instaceMeta.display_name }}</a
                    ></span
                  >
                </p>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="buttons">
                  <div class="file" :class="{ 'has-name': !!bulkfile }">
                    <b-upload v-model="bulkfile" class="file-label">
                      <span class="file-cta">
                        <b-icon class="file-icon" icon="upload"></b-icon>
                        <span class="file-label">Upload CSV</span>
                      </span>

                      <span class="file-name" v-if="bulkfile">
                        {{ bulkfile.name }}
                      </span>
                    </b-upload>
                  </div>
                  <div class="field">
                    <button
                      v-if="bulkfile"
                      @click="bulkCreate(instaceMeta.name)"
                      class="button"
                    >
                      <span class=""> Bulk Create </span>
                    </button>
                    <button
                      v-else
                      @click="closeCreateInstanc(instaceMeta.name)"
                      class="button"
                      :disabled="
                        Object.keys(newProcessRoles).length !=
                        instaceMeta.roles.length
                      "
                    >
                      <span class=""> Create </span>
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
            </div>
          </div>
          <div v-if="showBulkupload" class="just-box">
            <div class="popup-container center">
              <div class="columns">
                <div class="column">
                  <div class="">
                    <div class=" center" :class="{ 'has-name': !!bulkfile }">
                      <!-- <b-upload v-model="bulkfile" class="file-label">
                      <span class="file-cta">
                        <b-icon class="file-icon" icon="upload"></b-icon>
                        <span class="file-label">Upload CSV</span>
                      </span>

                      <span class="file-name" v-if="bulkfile">
                        {{ bulkfile.name }}
                      </span>
                    </b-upload> -->
                      <b-field>
                        <b-upload v-model="bulkfile" drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <span class="">
                                  <span class="icon" style="height: 60px;
                                                  width: 60px;">
                                    <img src="../assets/upload_cloud.png">
                                  </span>
                                </span>
                              </p>
                              <p>Drop your Spreadsheet files here or click to upload</p>
                              <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" />
                              <button class="button is-lightblue" @click="openFileInput">Browse File</button>
                            </div>
                          </section>
                        </b-upload>

                      </b-field>
                    </div>
                    <div class="center">


                      <div class="field has-addons" style="margin: 20px 46px;">
                        <p class="control">
                        <div class="button is-light center" v-if="bulkfile">
                          <span style="padding:0px 7px;">{{ bulkfile.name }}</span>
                        </div>
                        </p>

                        <p class="control">
                          <button v-if="bulkfile" @click="bulkCreate(instaceMeta.name)" class="button">
                            <span class=""> Bulk Create </span>
                          </button>

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="showCreateInstace || showBulkupload" class="just-box just-shadow-top just-box-rounded-bottom">
            <div class="center">
              <div>
                <button v-if="!showBulkupload" @click="closeCreateInstanc(instaceMeta.name)"
                  class="button is-rounded is-yellow" :disabled="isCreateDisabled(instaceMeta.roles.length)">
                  <span class=""> Create </span>
                </button>
                <div v-if="showBulkupload" class="tempate-string">
                  <div class="center">
                    <span>Don't have template</span>
                  </div>

                  <a class="button is-link is-lightblue is-rounded" :href="'/api/template/' + instaceMeta.name">

                    <span class="icon">
                      <img src="../assets/downloadbox.png">
                    </span>

                    <span>{{ instaceMeta.display_name }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- This popup is for verify ur form -->
      <b-modal v-model="verifyProcess">
        <div class="just-white rounded-box-container">
          <div class="just-box just-shadow-buttom just-box-rounded-top">
            <div class="">
              <div class="">
                <nav class="navbar-transparent" style="background-color: none !important" role="navigation"
                  aria-label="main navigation">
                  <div id="navbarBasicExample" class="navbar-menu">
                    <div class="navbar-start">
                      <div class="navbar-item">
                        <span>Verify Certificate</span>
                      </div>
                    </div>
                    <div class="navbar-end"></div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div class="popup-container">
            <verify></verify>
          </div>
         
          <div class="just-box just-shadow-top just-box-rounded-bottom verify-gradiednt">
            <div class="center">
              <span>Verify your document with Block-Chain based verification</span>
            </div>
          </div>
         
        </div>
      </b-modal>

      <div class="columns">
        <div class="column is-2">
          <div class=""></div>
        </div>
        <div class="column"></div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import "driver.js/dist/driver.min.css";
import { globalFunction } from "../services/global.function";
import { eventBus } from "../main";
import { glAPI } from "../services/glapi.js";
import { globalText } from "../resources/global.string.res.js";
import { dummyData } from "../resources/dummyData.js";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import VueGridLayout from "vue-grid-layout";
import DataTable from "primevue/datatable";
import Menubar from "primevue/menubar";
import Column from "primevue/column";
import Sidebar from "primevue/sidebar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Dialog from "primevue/dialog";
import Carousel from "primevue/carousel";
import Chips from "primevue/chips";
import Dropdown from "primevue/dropdown";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import InputText from "primevue/inputtext";
import { all, async } from "q";
import axios from "axios";
import Chart from "chart.js/auto";
import { marked } from "marked";
import boxDropdown from "../components/customDropdown.vue";
import newDropdown from "../components/newdropdown.vue";
import Tagsfield from "bulma-tagsfield";
import QbIcon from "../components/qbIcon.vue";
import qbloader from "@/components/qbloader.vue";

export default {
  data() {
    return {
      workflows: [],
      txns: [],
      uniqueKey: [],
      filtersReport: {
        user: { value: null, matchMode: FilterMatchMode.CONTAINS },
        employee: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      userString: "",
      fileContent: null,
      fileToRender: "/flows-md/i9bundle/",
      spin: "",
      verifing: false,
      isSignModalActive: false,
      ownFullName: "",
      isUp: false,
      historyEmitCounter: 0,
      subFolderItems: [],
      displayType: "grid",
      itemName: "",
      itemId: "",
      currentFolder: "",
      gt: globalText,
      actionTaken: false,
      visibleLeft: false,
      verifyPanel: false,
      createNewWorkflow: false,
      verifyWorkflow: false,
      showBulkupload: false,
      activeItem : false,
      infoData: {
        workflowId: "",
      },
      docType: 'normal',
      searchName: '',
      showCreateInstace: false,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
      instaceMeta: {
        description: "",
        name: "",
        display_name: "",
        roles: [],
      },
      colArr: [],
      createInstance: {
        users: [],
      },
      htmloutput: "Nothing to show",
      currentRoleSeleted: null,
      newProcessRoles: {},
      bulkfile: null,
      userRows: dummyData,
      doHideWelcome: false,
      displayData: false,
      dispalyForm: false,
      verifyProcess: false,
      currentColumns: [
        { field: "_id", header: "ID" },
        { field: "name", header: "Name" },
        { field: "gender", header: "Gender" },
        { field: "company", header: "Company" },
        { field: "email", header: "Email" },
        { field: "phone", header: "Phone" },
        { field: "address", header: "Address" },
        { field: "status", header: "Status" },
        { field: "registered", header: "Registered" },
      ],
      combinedCardsCurrent: "",
      combinedCards: [],
      selected: null,
      workflowDetails: null,
      stat1: 0,
      stat2: 0,
      stat3: 0,
      stat4: 0,
      stat5: 0,
      markdown: null,
      filteredInfo: [],
      currentSelectedCard: null,
      isFilterEnable: false,
      filterMe: false,
      isCardView: false,
      filterBy: '',
      currentuserDetails: null,
      enableLoader: false,
      displayImg: true,
      isDormatProcess : false,
      detailsOnSelect : false,
      gutterToggleId : null,
      currentSelectedFormID: null,
      isReportType : false
    };
  },

  computed: {
    currentStats() {
      return this.$store.getters.stats;
    },
    actualWorkFlowID() {
      return this.$store.getters.getWorkFlowID;
    },
    getBundles() {
      let allBundls = this.$store.getters.bundles;
      for (let i = 0; i < allBundls.length; i++) {
        for (let j = 0; j < allBundls[i].workflows.length; j++) {
          let item = allBundls[i].workflows[j];
          item["id"] = j;
        }
        console.log("For loop", allBundls[i]);
      }
      let available = [...this.$store.getters.availables];
      for (let k = 0; k < available.length; k++) {
        let use = [];
        use.push({
          display_name: available[k].display_name,
          id: available[k].id,
          name: available[k].name,
        });
        available[k].workflows = use;
      }
      console.log("available", available);
      allBundls = [...allBundls, ...available];
      console.log("All Bundle", allBundls);
      for (let k = 0; k < allBundls.length; k++) {
        allBundls[k].id = k;
      }
      return allBundls;
    },
    filteredDataObj() {
      const newData = []
      let workflows = []
      let reports = []
        this.getBundles.forEach(element => {
          console.log("bundel ",element)
           workflows = element.workflows.filter(item =>
            item.display_name.toLowerCase().indexOf(this.searchName.toLowerCase()) >= 0)
          if(element.reports){
            reports = element.reports.filter(item =>
            item.display_name.toLowerCase().indexOf(this.searchName.toLowerCase()) >= 0)
            
          }
          if (workflows.length) {
            newData.push({ name: element.name, workflows,reports, id: element.id })
          }
          reports = []
        })
        console.log("new data",newData)
      return newData
    },
    operationIsLoading: {
      get: function () {
        return this.$store.getters.isLoading;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("isLoading", newValue);
      },
    },
    getWorkFlowHistory() {
      console.log("Tis from other", this.$store.getters.getWorkFlowID);
      return this.$store.getters.getHistory;
    },
    getSubFolderItems() {
      return this.$store.getters.getsubFoldersItem;
    },
    getWorkflowInfo:{
      get: function(){
        let info = this.$store.getters.getWorkFlowInfo;
        return info;
      },
      set: function(newValue){
        this.$store.commit("workFlowInfo",newValue)
      }
    },
    getPendingAndCompleted() {
      let pending = this.$store.getters.pendings;
      let completed = this.$store.getters.archived;
      this.combinedCards = [...pending, ...completed];

      console.log("all combine data", this.combinedCards)
      return this.combinedCards;
    },
    filteredDataArray() {
      if (this.isCardView) {
        return this.combinedCards.filter((option) => {
          return (
            option.display_name
              .toString()
              .toLowerCase()
              .indexOf(this.combinedCardsCurrent.toLowerCase()) >= 0
          );
        });
      } else {
        let actions = this.$store.getters.actions
        console.log("actions pending",actions)
        if (actions.hasOwnProperty("pending")) {
          actions.pending.forEach((v,i)=> {
            v.actiontype = "pending"
          })
          return actions.pending.filter((option) => {
            return (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.combinedCardsCurrent.toLowerCase()) >= 0
            );
          });
        }
      }
    },
    dormantProcess(){
      let actions = this.$store.getters.actions
      
        if (actions.hasOwnProperty("dormant")) {
          const newData = []
          let workflows =[]
          let reports =[]
          Object.keys(actions.dormant).forEach((element,index) => {
            if(actions.dormant[element].workflows){
           workflows = actions.dormant[element].workflows.filter(item =>
            item.name.toLowerCase().indexOf(this.searchName.toLowerCase()) >= 0)
            } else {
              workflows = [];
            }
            workflows.forEach((item,index) => {
              item.cardid = index
            } )
            if(actions.dormant[element].reports){
               reports = actions.dormant[element].reports.filter(item =>
              item.name.toLowerCase().indexOf(this.searchName.toLowerCase()) >= 0)
            } else {
              reports = [];
            }
          if (workflows.length || reports.length) {
            newData.push({ name: element, workflows,reports, id: index })
          }
        })
        console.log("new data dorment",newData)
         return newData
        
      }
    },
    getWorkFlowDetails() {
      return this.$store.getters.getWorkFlowDetails;
    },

    sortList() {
      let tobesorted = [
        ...this.$store.getters.pendings,
        ...this.$store.getters.archived,
      ];
      return tobesorted
        .sort((a, b) => {
          if (a.expires != "NA" && b.expires != "NA") {
            console.log("Sort", a.expires, ":", b.expires);
            return new Date(a.expires) < new Date(b.expires) ? 1 : -1;
          }
        })
        .reverse();

      // console.log("filterd",allfilArr,this.filteredDataArray,this.combinedCards)
    },
  },
  watch: {
    isCardView: {
      handler(newValue, oldValue) {
        console.log("card view:", newValue, oldValue);
        const val = newValue ? "true" : "false";
        window.localStorage.setItem("homeview", val);
      }
    },
    getWorkFlowHistory: {
      handler(newValue, oldValue) {
        console.log("History Values", newValue, oldValue);
        this.txns = newValue.transactions;
        this.itemId = newValue.itemId;
        this.itemName = newValue.itemName;
        this.doHideWelcome = true;
        // this.slideup();
      },
      deep: true,
    },
    getWorkFlowDetails: {
      handler(newValue, oldValue) {
        console.log("Details Values", newValue, oldValue);
        this.workflowDetails = newValue;
      },
      deep: true,
    },
    getSubFolderItems: {
      handler(newValue, oldValue) {
        console.log("Sub items", newValue);
        this.currentFolder = newValue.currentSeletedItem;
        this.subFolderItems = newValue.data;
        // if (this.subFolderItems.length > 0) this.slideup();
      },
      deep: true,
    },
    getWorkflowInfo: {
      handler(newValue, oldValue) {
        console.log("info items", newValue);
        this.infoData = newValue;

        if (this.colArr.length > 0) {
          this.colArr = [];
        }
        if(!this.isDormatProcess){
          this.filterSelectedData(
            this.infoData.workflowId,
            this.filteredDataArray
          );
        }

        this.getHelpMarkDown(this.infoData.name);

        this.getTableMeta(this.infoData.query, "field");
        this.resetTabs();
        this.resetTabs_v1()
      },
      deep: true,
    },
    getPendingAndCompleted: {
      handler(newValue, oldValue) {
        console.log("All Arr value", newValue);
      },
      deep: true,
    },
  },
  components: {
    signpopup: () => import("../components/signPopup.vue"),
    reports : () => import("../components/reports.vue"),
    ffc: () => import("../components/folderComponent.vue"),
    constgrid: () => import("../components/uniformGrid.vue"),
    cbnt: () => import("../components/customButtonUp.vue"),
    verify: () => import("../components/verify.vue"),
    history: () => import("../components/history.vue"),
    detailsTab: () => import("../components/detailstabcontent.vue"),
    expander: () => import("../components/expander.vue"),
    DataTable,
    Splitter,
    SplitterPanel,
    Menubar,
    Column,
    Sidebar,
    AccordionTab,
    Accordion,
    Dialog,
    Carousel,
    Chips,
    Dropdown,
    FilterMatchMode,
    FilterOperator,
    InputText,
    boxDropdown,
    QbIcon,
    Tagsfield,
    qbloader,
    newDropdown
  },
  created() {
    // this.getHelpMarkDown();
    eventBus.$on("create_popup", this.enableCreatePop);
    eventBus.$on("verify_popup", this.enableVerifyPop);    
  },
  mounted: async function () {
    const val = window.localStorage.getItem("homeview");
    if (!val || val == "false") {
      this.isCardView = false;
    } else {
      this.isCardView = val;
    }

    await this.init();

    this.$root.$on("getHistory", (data) => {
      console.log("Catching emit");

      this.intiateWorkflow(data);
    });

    this.$root.$on("open_form", (data) => {
      
      console.log("open_form", data);
      this.currentSelectedCard = data.cardid;
      if(data.docType == 'report'){
        this.isReportType = true
      }
      
      this.dispalyForm = true;
      this.displayData = false;
    });
    let globalThis = this;
    this.$root.$on("select_card", async function (data) {
      console.log("select_card", data);
      globalThis.dispalyForm = false;
      globalThis.displayData = true;
      this.currentSelectedCard = data
      globalThis.resetTabs();
    });
    this.$root.$on("close_form", () => {
      console.log("close_form recvd");
      this.dispalyForm = false;
      this.isReportType = false;
    })
  },
  methods: {
    exportCSV() {
      this.$refs.reportdt.exportCSV();
    },
    onSelect: async function (
      itemid,
      itemName,
      cardid
    ) {
      this.isDormatProcess = false
      this.detailsOnSelect = true;
      // this.$emit("selected", cardid);
      this.enableLoader = true
      this.displayImg = false
      this.getItemHistroy(itemid, cardid, "pending");
      // this.$root.$emit("select_card", cardid);
      this.getItemDetails(itemid, itemName);
      // this.enableLoader = false
    },
    onSelectDormat: async function (
      payload
    ) {
      
      // this.$emit("selected", cardid);
      // this.getWorkflowInfo = this.$store.getters.getWorkFlowInfo;
      this.detailsOnSelect = true;
      this.enableLoader = true
      this.displayImg = false
      this.isDormatProcess = true

      let processType = "dormant"
      this.gutterToggleId = payload.toggleid
      this.getItemHistroy(payload.id, payload.currentSelectedID , processType);
      // this.$root.$emit("select_card", cardid);
      this.getItemDetails(payload.id, payload.DormantName);
       
      console.log("dr ",this.dormantProcess,typeof(this.dormantProcess))
      let selectedprocess = this.dormantProcess.find(obj => obj.name === payload.bundleName);
      this.filterSelectedData(
        payload.id,
        selectedprocess.workflows,
          "dormant"
        );
      // this.filteredInfo = this.$store.getters.getWorkFlowInfo
      // this.enableLoader = false
    },
    getItemDetails: async function (itemid, itemName) {
      await this.$store.dispatch("FETCH_DETAILS", { itemid, itemName });
    },
    getItemHistroy: async function (value, cardid, processType) {
      await this.$store.dispatch("FETCH_HISTORY", { value, cardid ,processType});
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      // Do something with the selected file, like sending it to the server
      this.bulkfile = file
      // You can also access the file properties, such as name and size
      console.log('File name:', file.name);
      console.log('File size:', file.size);
    },
    backButtonHandler() {
      if (this.showBulkupload) {
        this.showBulkupload = false;
        return
      }
      if (this.showCreateInstace) {
        this.showCreateInstace = false;
        return
      }
      if (this.createNewWorkflow) {
        this.createNewWorkflow = false;
      }
    },

    openForm: async function (workflowid, cardid,toggleid, processType) {
      
      console.log("Open the Form of id :", this.currentSelectedCard, workflowid, cardid);
      let value = workflowid
      this.displayData = false;
      this.operationIsLoading = true;
      this.detailsOnSelect = false;
      this.displayImg = true
      this.gutterToggleId = toggleid
      this.currentSelectedFormID = cardid
      if(workflowid.includes('re-')){
          this.isReportType = true
          await this.$store.dispatch("FETCH_REPORT_DETAILS", {workflowid});
          
      }else{
        await this.$store.dispatch("FETCH_HISTORY", { value, cardid , processType});
        await this.$store.dispatch("SELECT_WORKFLOW", { value });
      }
      
      this.operationIsLoading = false;
      this.dispalyForm = true;
    },
    showbulkupload() {
      this.showBulkupload = true;
      this.showCreateInstace = false;
    },
    enableCreatePop(data) {
      console.log("popupdata", data);
      this.createNewWorkflow = !this.createNewWorkflow;
    },
    enableVerifyPop(data) {
      this.verifyProcess = !this.verifyProcess;
    },
    genNum(min, max) {
      return globalFunction.randomNumGenerator(min, max);
    },
    filterSelectedData(workflowId, data , proccesstype) {
      
      if(proccesstype != undefined){
        this.filteredInfo = data.filter((item) => {
        return item.id == workflowId;
      });
      
      }else{
        this.filteredInfo = data.filter((item) => {
        return item.id == workflowId;
      });
     
      }
      console.log("filteredInfo", this.filteredInfo);
      
      return this.filteredInfo[0];
    },
    changeSelection(n) {
      console.log("On selected", n);
      this.selected = n;
      this.dispalyForm = false;
      this.displayData = true;
      this.resetTabs();
    },
    clearWorkFlow: async function () {
      try {
        this.operationIsLoading = true;
        await this.$store.dispatch("CLEAR_WORKFLOW");
        this.operationIsLoading = false;
      } catch (error) {
        console.log("clear workflow failed:", error);
        this.operationIsLoading = false;
      }
    },
    intiateWorkflow: async function (data) {
      console.log("API call", data);
      this.currentItemName = data.name;
      this.historyEmitCounter++;
      if (this.historyEmitCounter <= 1) {
        await this.selectWorkFlow(data.id);
      }
    },
    getHelpMarkDown: async function (filename) {
      this.fileContent = "rendering ";
      // var self;
      if (filename) {
        try {
          let response = await axios.get(
            this.fileToRender + filename + ".md",
            {}
          );
          // console.log("Res", response);
          this.fileContent = response.data;
          // console.log("Content", this.fileContent);  
          this.htmloutput = marked(this.fileContent);
          // console.log("Content output", this.htmloutput);
        } catch (error) {
          console.log("failed to get help content: ", error);
        }
      }

    },
    enableFilter: function () {
      this.isFilterEnable = !this.isFilterEnable;
      if (this.isFilterEnable) {
        this.filterBy = "Urgent";
        this.filterMe = true;
      } else {
        this.filterBy = "";
        this.filterMe = false;
      }
    },
    onUpload(event, file) {
      console.log("Upload event", event, file);
      if (!file) {
        return false;
      } else {
        return true;
      }
    },
    withUpload(id, indexid, file) {
      console.log("with upload", id, indexid, file);
      if (id == indexid) {
        this.bulkfile = file;
      } else {
        this.bulkfile = null;
      }
    },
    testing: async function (value, display_name, doc_type) {
      console.log('event',value)
      if(doc_type == 'normal'){
      let result = await axios.get("/api/template/" + value + "/details", {});

      console.log("Result Data", result);
      this.instaceMeta.description = result.data.description;
      this.instaceMeta.name = result.data.name;
      this.instaceMeta.roles = result.data.roles;
      this.instaceMeta.display_name = display_name;
      this.currentRoleSeleted = this.instaceMeta.roles[0] || null;

      this.showCreateInstace = true;
      this.docType = 'normal'
      }else{
        this.instaceMeta.name = value;
        this.showCreateInstace = true;
        this.docType = 'report'
      }
    },
    isCreateDisabled(roleLength){
      if(Object.keys(this.newProcessRoles).length != roleLength && this.docType == 'normal'){
        return true
      }else{
        return false
      }
    },
    closeCreateInstanc: async function(instanceName) {
      console.log("Is closing", this.instaceMeta, ":", this.newProcessRoles);

      let createNewInstance = new glAPI(true);
      if(this.docType == 'normal'){
      if (
        Object.values(this.newProcessRoles).every((x) => x.length > 0) &&
        Object.keys(this.newProcessRoles).length ==
        this.instaceMeta.roles.length
      ) {
        createNewInstance.createInstance({
          name: instanceName,
          roles: this.newProcessRoles,
        });
        this.showCreateInstace = false;
        this.createNewWorkflow = false;
        // this.init();
      } else {
        globalFunction.notify(
          "is-danger",
          "All the roles must have atleast 1 user"
        );
        console.log("not all roles mapped to user!!");
      }
    }else{
      let usersArr = {
       users : this.createInstance.users
      }
      let result = await axios.post("/api/report/" + instanceName + "/create", usersArr);
      this.showCreateInstace = false;
      this.createNewWorkflow = false;
      console.log("Report create",result)
    }
      this.instaceMeta = {
        description: "",
        name: "",
        display_name: "",
        roles: [],
      };
      this.createInstance = {
        users: [],
      };
      this.userString = "";
    },
    bulkCreate: async function (instanceName) {
      var formData = new FormData();
      formData.append("file", this.bulkfile);
      let result = await axios.post(
        "/api/template/" + instanceName + "/create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (result.status != 200) {
        globalFunction.notify("is-danger", "Failed to Create");
      } else {
        globalFunction.notify("is-success", "Created Succesfully");
        this.showCreateInstace = false;
        this.createNewWorkflow = false;
      }
      this.bulkfile = null;
      console.log("Post result", result);
    },
    updateRoleSate(currentRoleSeleted) {
      this.createInstance.users = [];
      this.userString = ""
      for (const item in this.newProcessRoles) {
        if (currentRoleSeleted == item) {
          this.createInstance.users = this.newProcessRoles[item];
        }
      }
    },
    updatevalue(currentRoleSeleted, event) {
      console.log(
        "Current Selection",
        currentRoleSeleted,
        "and user",
        this.createInstance.users,
        event
      );

      this.newProcessRoles[currentRoleSeleted] = this.createInstance.users;

      console.log("new created data", this.newProcessRoles);
    },
    async addToArr(currentRoleSeleted) {
      const values = this.userString.split(",");

      await values.forEach((value, key) => {
        const trimmedValue = value.trim();
        if (trimmedValue !== "") {
          this.createInstance.users.push(trimmedValue);
        }
      });

      this.createInstance.users = [...new Set(this.createInstance.users)]
      this.newProcessRoles[currentRoleSeleted] = this.createInstance.users;
      console.log("Send obje", this.newProcessRoles)
    },
    selectWorkFlow: async function (value) {
      console.log("workflow value:", value);
      if (this.historyEmitCounter <= 1) {
        try {
          this.operationIsLoading = true;
          await this.$store.dispatch("SELECT_WORKFLOW", { value });
          this.operationIsLoading = false;
          if (this.actualWorkFlowID === value) {
            globalFunction.notify(
              "is-success",
              "workflow selected successfully"
            );
            this.txns = this.$store.getters.transactions;
            console.log("Transantion", this.txns);
            // if (this.txns) {
            //   this.slideup();
            // }
          } else {
            console.log("workflow selection failed");
            globalFunction.notify(
              "is-danger",
              "could not fetch workflow details, please select another workflow"
            );
          }
        } catch (error) {
          this.operationIsLoading = false;
          console.log("selectWorkflow error:", error);
        }
        this.historyEmitCounter = +1;
      }
    },
    slideup: function (itemId) {
      let slideUpEl = this.$refs.slideuppanel;
      let centerCircleEl = this.$refs.centercircle;
      let bottomCircleEl = this.$refs.bottomcircle;
      let slideUpBtnEl = this.$refs.slideupbtn;
      console.log(bottomCircleEl, slideUpEl, slideUpEl);
      // this.selectWorkFlow(itemId);
      this.isUp = !this.isUp;
      if (this.isUp) {
        // bottomCircleEl.style.transform = "translatex(-50%) scale(0)";
        slideUpEl.classList.remove("slide-down-anim");
        slideUpEl.classList.add("slide-up-anim");
      } else {
        // bottomCircleEl.style.transform = "translatex(-50%) scale(1)";
        slideUpEl.classList.remove("slide-up-anim");
        slideUpEl.classList.add("slide-down-anim");
        this.historyEmitCounter = 0;
      }

      return this.isUp;
    },
    closeSlidUp() {
      let slideUpEl = this.$refs.slideuppanel;
      console.log("this close up is been called");
      if (this.isUp) {
        slideUpEl.classList.remove("slide-up-anim");
        slideUpEl.classList.add("slide-down-anim");
        // this.subFolderItems = [];

        this.historyEmitCounter = 0;
        this.isUp = false;
        this.$store.dispatch("WORKFLOW_SUBFOLDER", { data: [] });
      }
      console.log("Clearing", this.subFolderItems);
    },
    getTableMeta(colObj, type) {
      let col = {};
      let keys = [];
      if (colObj.length > 0) {
        colObj.forEach((item) => {
          keys = [...Object.keys(item)];
        });

        this.uniqueKey = [...new Set(keys)];

        console.log("Col keys", keys, this.uniqueKey);
        for (let i = 0; i < this.uniqueKey.length; i++) {
          console.log("this string", this.uniqueKey[i]);
          this.colArr.push({
            field: this.uniqueKey[i],
            header:
              this.uniqueKey[i].charAt(0).toUpperCase() +
              this.uniqueKey[i].slice(1),
            key: this.uniqueKey[i],
          });
          this.filtersReport[this.uniqueKey[i]] = {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
          };
        }

        console.log("Cols for Tables", col, this.colArr);
        return this.colArr;
      }
    },
    clearFilter() {
      this.filtersReport["user"].value = null;
      this.filtersReport["employee"].value = null;
    },
    setStatusClass(status) {
      switch (status) {
        case true:
          return "is-success";
          break;
        case false:
          return "is-danger";
          break;
        default:
          return "is-light";
          break;
      }
    },
    resetTabs() {
      const r = document.querySelectorAll(".tabs");
      console.log(r);
      document.querySelectorAll(".tabs").forEach((tab) => {
        tab.querySelectorAll("li").forEach((li) => {
          li.onclick = () => {
            console.log("Active Tab", tab.querySelector("li.is-active"));
            tab.querySelector("li.is-active").classList.remove("is-active");
            li.classList.add("is-active");
            tab.previousElementSibling
              .querySelector(".tab-pane.is-active")
              .classList.remove("is-active");
            tab.previousElementSibling
              .querySelector(
                ".tab-pane#" + li.firstElementChild.getAttribute("id")
              )
              .classList.add("is-active");
          };
        });
      });
    },
    resetTabs_v1() {
      const r = document.querySelectorAll(".tabs-v1");
      console.log(r);
      document.querySelectorAll(".tabs-v1").forEach((tab) => {
        tab.querySelectorAll("li").forEach((li) => {
          li.onclick = () => {
            console.log("Active Tab", tab.querySelector("li.is-active"));
            tab.querySelector("li.is-active").classList.remove("is-active");
            li.classList.add("is-active");
            tab.previousElementSibling
              .querySelector(".tab-pane.is-active")
              .classList.remove("is-active");
            tab.previousElementSibling
              .querySelector(
                ".tab-pane#" + li.firstElementChild.getAttribute("id")
              )
              .classList.add("is-active");
          };
        });
      });
    },
    init: async function () {

      // Tabs Start
      this.stat1 = this.genNum(1, 10);
      this.stat2 = this.genNum(1, 10);
      this.stat3 = this.genNum(1, 10);
      this.stat4 = this.genNum(1, 10);
      this.stat5 = this.genNum(1, 10);
      console.log(document.querySelectorAll(".tabs"));
      document.querySelectorAll(".tabs").forEach((tab) => {
        tab.querySelectorAll("li").forEach((li) => {
          li.onclick = () => {
            console.log("Active Tab", tab.querySelector("li.is-active"));
            tab.querySelector("li.is-active").classList.remove("is-active");
            li.classList.add("is-active");
            tab.previousElementSibling
              .querySelector(".tab-pane.is-active")
              .classList.remove("is-active");
            tab.previousElementSibling
              .querySelector(
                ".tab-pane#" + li.firstElementChild.getAttribute("id")
              )
              .classList.add("is-active");
          };
        });
      });
      console.log("tabv1", document.querySelectorAll(".tabs-v1"));
      document.querySelectorAll(".tabs-v1").forEach((tab) => {
        
        tab.querySelectorAll("li").forEach((li) => {
          li.onclick = () => {
            
            console.log("Active Tab", tab.querySelector("li.is-active"));
            tab.querySelector("li.is-active").classList.remove("is-active");
            li.classList.add("is-active");
            tab.previousElementSibling
              .querySelector(".tab-pane.is-active")
              .classList.remove("is-active");
            tab.previousElementSibling
              .querySelector(
                ".tab-pane#" + li.firstElementChild.getAttribute("id")
              )
              .classList.add("is-active");
          };
        });
      });
      if (
        !this.$store.getters.authFlowObject ||
        !this.$store.getters.authFlowObject.isSignedIn()
      ) {
        this.$router.push("/");
      }
      try {
        this.operationIsLoading = true;
        await this.$store.dispatch("FETCH_WORKFLOWS");
        this.operationIsLoading = false;
      } catch (error) {
        console.log("failed to fetch workflows:", error);
        this.operationIsLoading = false;
      }
      this.currentuserDetails = this.$store.getters.getUserDetails;      
    },
  },
  events: {},
  beforeDestroy() {
    this.$root.$off("item-clicked");
  },
};
</script>

<style lang="scss">
.slide-up-panel-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.slide-up-panel {
  position: absolute;
  bottom: 0;
  // display: flex;
  justify-content: center;

  width: -webkit-fill-available;
  height: 46px;
  background-color: #ffffff;
  box-shadow: 0 10px 45px rgb(10 10 10 / 10%), 0 0 0 0px rgb(10 10 10 / 0%);
}

.slide-up-btn {
  margin-top: 12vmin;

  height: 1vmin;
  width: 10vmin;
  background-color: whitesmoke;
  cursor: pointer;
}

.slide-up-anim {
  animation: slide-up-anim 0.5s ease-in-out forwards;
}

@keyframes slide-up-anim {
  0% {
    height: 46px;
  }

  50% {
    height: 33px;
  }

  100% {
    height: 500px;
  }
}

.slide-down-anim {
  animation: slide-down-anim 0.5s ease-in-out forwards;
}

@keyframes slide-down-anim {
  0% {
    height: 500px;
  }

  50% {
    height: 510px;
  }

  100% {
    height: 46px;
  }
}

.bottom-fixed {
  height: 100px;
}

.p-1 {
  padding: 0px !important;
}

.p-datatable-wrapper {
  height: 400px !important;
}

.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }
}

.b-sidebar {
  .sidebar-content {
    &.is-mini-mobile {

      &:not(.is-mini-expand),
      &.is-mini-expand:not(:hover) {
        .menu-list {
          li {
            a {
              span:nth-child(2) {
                display: none;
              }
            }

            ul {
              padding-left: 0;

              li {
                a {
                  display: inline-block;
                }
              }
            }
          }
        }

        .menu-label:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {

        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }

              ul {
                padding-left: 0;

                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }

          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.b-tooltips {
  .b-tooltip:not(:last-child) {
    margin-right: 0.5em;
  }

  .b-tooltip {
    margin-bottom: 0.5em;
  }

  span {
    width: 100%;
  }
}

.modal-close {
  background: rgba(10, 10, 10, 0.3) !important;
}

.pendingdiscrption:hover{
    font-weight: 800;
    cursor: pointer;
  }
  .p-hidden-accessible{
  display: none;
}

.pendingdiscrption.active{
  color: #3199ee;
}
</style>
