<template>
    <div class="grid-dropdown-container">
      
      <div >
        <div class="">
          <div class="">
            <div
            
              class="new-dropdown full-width dropdown"
              
            >
              <div class="dropdown-trigger full-width" style="z-index: 24">
                <div
                  class=""
                  aria-haspopup="true"
                  aria-controls="dropdown-menu2"
                >
                  <div class="columns">
                    <div class="column">
                      <div class="on-end" style="height: 100%">
                        <div class="center"   @click="toggle()" >
                          <span v-if="isExpanded" class="icon-text">
                            
                            <span class="icon">
                              <img src="../assets/blue-cicle-filled-down.png" />
                            </span>
                          </span>
                          <span v-else class="icon-text">
                            <span class="icon">
                              <img src="../assets/blue-circle-down.png" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-10" style="padding : 0.75rem 0rem;"   >
                      <div  class="title is-5 " >
                        {{ dropdownName }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="dropdown-menu dropdown-menu-custom full-width"
                id="dropdown-menu2"
                role="menu"
                v-show="isExpanded"
              >
                <div class="">
                  <div
                    v-for="(j, indx) in elementArray"
                    :key="j.id"
                    class="dropdown-item"
                    style="padding: 0px 0px 0px 37px;line-height: 1.3;font-size: 16px;"
                  >
                 
                    <div class="list-item">
                      <div class="list-item-content">
                        <div class=""   @click="selected(j.id,dropdownName, j.name, indx)">
                         
                          <span>
                            <span>{{ j.description }} </span>
                          </span>
                         
                        </div>
                      </div>
  
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-for="i in getbundles" :key="i.name"  class="dropdown">
            <div class="dropdown-trigger">
              <button class="button"
              @click="toggle"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu">
                <span>{{ i.name }}</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down"
                     aria-hidden="true"></i>
                </span>
              </button>
            </div>
   
            <div class="dropdown-menu"
                 id="dropdown-menu"
                 role="menu">
              <div  v-for="(j, indx) in i.workflows"
                    :key="j.id" class="dropdown-content">
                <a href="#" class="dropdown-item">
                      {{ j.display_name }}
                </a>
              </div>
            </div>
          </div>
         -->
    </div>
  </template>
  
  <script>
  export default {
    name: "boxDropdown",
    props: {
      elementArray : Array,
      dropdownName : String
    },
    data() {
      return {
        isExpanded: false,
        selectedOptions: [],
        currentId: null,
        dropdowns: [
          {
            options: [
              { label: "Option 1.1", value: "option1.1" },
              { label: "Option 1.2", value: "option1.2" },
              { label: "Option 1.3", value: "option1.3" },
            ],
          },
          {
            options: [
              { label: "Option 2.1", value: "option2.1" },
              { label: "Option 2.2", value: "option2.2" },
              { label: "Option 2.3", value: "option2.3" },
            ],
          },
          {
            options: [
              { label: "Option 3.1", value: "option3.1" },
              { label: "Option 3.2", value: "option3.2" },
              { label: "Option 3.3", value: "option3.3" },
            ],
          },
        ],
      };
    },
    mounted() {
      const dropdown = document.querySelector(".dropdown");
      const active = document.querySelector(".is-active");
      document.body.addEventListener("click", function () {
        if (active) {
          dropdown.classList.remove("is-active");
        }
      });
      dropdown.addEventListener("click", function (event) {
        event.stopPropagation();
        this.classList.toggle("is-active");
      });
    },
    methods: {
      toggle(id) {
     
        this.isExpanded = !this.isExpanded;

      },
     
      selected: async function (id,bundleName, DormantName, currentSelectedID) {
        
        this.$emit("onitemClicked", {id,bundleName, DormantName,currentSelectedID});
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .dropdown-item:hover{
    font-weight: 800;
    cursor: pointer;
  }
  .dropdown-menu-custom {
  display: none;
  left: 0;
  min-width: 12rem;
  padding-top: 0px !important;
  position: relative !important;
  top: 34% !important;
  z-index: 20;
}

.dropdown-contain-custom {
  padding-top: 43px !important;
  margin-bottom: 20px !important;
  border-bottom-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}

.new-dropdown {
  display: block !important;
}

.box-on-top {
  position: relative;
  z-index: 50;
}

.dropdown.is-active .dropdown-menu,
.dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
//   margin-top: -43px !important;
}
  </style>
  