<template>
    <div class="grid-dropdown-container">
<!-- {{ getbundles }} -->
        <div v-for="(i, x) in getbundles" :key="i.name" class="">
            <div class="">
                <div class="">
                    
                    <div class="new-dropdown full-width dropdown" :class="{ 'is-active': x == i.id }">
                        <div class="dropdown-trigger full-width" style="z-index: 24">
                            <div class="" aria-haspopup="true" aria-controls="dropdown-menu2">
                                <div class="columns">
                                    <div class="column">
                                        <div class="on-end" style="height: 100%">
                                            <div class="center" @click="toggle(i.id)">
                                                <span v-if="isExpanded && currentId == i.id" class="icon-text">

                                                    <span class="icon">
                                                        <img src="../assets/blue-cicle-filled-down.png" />
                                                    </span>
                                                </span>
                                                <span v-else class="icon-text">
                                                    <span class="icon">
                                                        <img src="../assets/blue-circle-down.png" />
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column is-10" style="padding : 0.75rem 0rem;">
                                        <div v-if="i.display_name" class="title is-5 ">
                                            {{ i.display_name }}
                                        </div>
                                        <div v-else class="title is-6">{{ i.name }}</div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        
                        <div class="dropdown-menu dropdown-menu-custom full-width" id="dropdown-menu2" role="menu"
                            v-show="isExpanded && x == currentId">
                            <div class="bundel-subhedding">WorkFlows</div>
                            <div class="line-12"></div>
                            <div class="">
                                <div v-for="(j, indx) in i.workflows" :key="j.id" class="dropdown-item"
                                    style="padding: 0px 0px 0px 37px;line-height: 1.3;font-size: 16px;">
                                      
                                    <div class="list-item" style="    padding: 10px 2px;">
                                        <div class="list-item-content">
                                            <div :class="{ itemdiscription:true, active:  j.cardid == currentSeleteditem && processType == 'dormant' }" 
                                            @click="selected(j.id, i.name, j.name, i.id, indx)">

                                                <span>
                                                    <span>{{ j.description }} </span>
                                                </span>

                                            </div>
                                            <div class="on-end" style="margin: 10px 0px;">
                                                <button @click="emitOpenForm(j.id, i.id, indx , 'dormant')"
                                                    class="button extraSmall is-newaccent is-rounded">
                                                    Launch
                                                </button>
                                            </div>
                                           
                                        </div>
                                        

                                    </div>
                                   
                                </div>
                            </div>

                            <!-- <basicDropdown @onitemClicked="subItemSelected($event)" :dropdownName="'Reports'" :elementArray="i.reports"></basicDropdown> -->
                            <div v-if="i.reports.length">
                                <div class="bundel-subhedding">Reports</div>
                                <div class="line-12"></div>
                                <div class="">
                                    <div v-for="(k, indx) in i.reports" :key="k.id" class="dropdown-item"
                                        style="padding: 0px 0px 0px 37px;line-height: 1.3;font-size: 16px;">

                                        <div class="list-item" style="    padding: 10px 2px;">
                                            <div class="list-item-content">
                                                <div class="reportdiscription">

                                                    <span>
                                                        <span>{{ k.description }} </span>
                                                    </span>

                                                </div>
                                                <div class="on-end" style="    margin: 10px 0px;">
                                                    <button @click="emitOpenForm(k.id,i.id, indx,'dormant')"
                                                        class="button extraSmall is-newaccent is-rounded">
                                                        Launch
                                                    </button>
                                                </div>
                                                    
                                            </div>
                                            

                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-for="i in getbundles" :key="i.name"  class="dropdown">
            <div class="dropdown-trigger">
              <button class="button"
              @click="toggle"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu">
                <span>{{ i.name }}</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down"
                     aria-hidden="true"></i>
                </span>
              </button>
            </div>
   
            <div class="dropdown-menu"
                 id="dropdown-menu"
                 role="menu">
              <div  v-for="(j, indx) in i.workflows"
                    :key="j.id" class="dropdown-content">
                <a href="#" class="dropdown-item">
                      {{ j.display_name }}
                </a>
              </div>
            </div>
          </div>
         -->
    </div>
</template>
  
<script>
import basicDropdown from "./basicDropdown.vue";

export default {
    name: "boxDropdown",
    props: {
        getbundles: Array,
        processType : String,
        istoggedfor : Number,
        selectedForm : Number
    },
    components: {
        basicDropdown
    },
    data() {
        return {
            isExpanded: false,
            selectedOptions: [],
            currentSeleteditem : null,
            currentId: null,
            dropdowns: [
                {
                    options: [
                        { label: "Option 1.1", value: "option1.1" },
                        { label: "Option 1.2", value: "option1.2" },
                        { label: "Option 1.3", value: "option1.3" },
                    ],
                },
                {
                    options: [
                        { label: "Option 2.1", value: "option2.1" },
                        { label: "Option 2.2", value: "option2.2" },
                        { label: "Option 2.3", value: "option2.3" },
                    ],
                },
                {
                    options: [
                        { label: "Option 3.1", value: "option3.1" },
                        { label: "Option 3.2", value: "option3.2" },
                        { label: "Option 3.3", value: "option3.3" },
                    ],
                },
            ],
        };
    },
    mounted() {
        // this.toggle(this.istoggedfor)
        
        const dropdown = document.querySelector(".dropdown");
        const active = document.querySelector(".is-active");
        document.body.addEventListener("click", function () {
            if (active) {
                dropdown.classList.remove("is-active");
            }
        });
        dropdown.addEventListener("click", function (event) {
            event.stopPropagation();
            this.classList.toggle("is-active");
        });
    },
    methods: {
        toggle(id) {
            //   this.$emit("toggle-dropdown");
            
            this.isExpanded = true
            
            this.currentId = id;

            
            this.currentSeleteditem = this.selectedForm;
              const dropdown = document.querySelector(".dropdown-menu");
              dropdown.classList.add("is-active");
              const active = document.querySelector(".is-active");
              console.log("active drop",dropdown.classList)
              console.log('active call', active)
              
        },
        tog() {
            const dropdown = document.querySelector(".dropdown");
            const active = document.querySelector(".is-active");
            document.body.addEventListener("click", function () {
                if (active) {
                    dropdown.classList.remove("is-active");
                }
            });
            // dropdown.addEventListener('click', function (event) {
            //   event.stopPropagation();
            //   this.classList. ('is-active');
            // });
        },
        selected: async function (id, bundleName, DormantName,toggleid, currentSelectedID) {
            this.currentSeleteditem = currentSelectedID
            this.$emit("process_selected", { id, bundleName, DormantName,toggleid, currentSelectedID });
        },
        subItemSelected: async function (payload) {
           
            this.$emit("process_selected", payload);
        },
        emitOpenForm: async function (id,toggleid, indexid,processType) {
            this.currentSeleteditem = indexid
            this.$emit("onLunch", { id,toggleid, indexid ,processType});
        },
    },
};
</script>
<style lang="scss" scoped>
.itemdiscription:hover {
    font-weight: 800;
    cursor: pointer;
}

.dropdown-menu-custom {
    display: none;
    left: 0;
    min-width: 12rem;
    padding-top: 0px !important;
    position: relative !important;
    top: 34% !important;
    z-index: 20;
}

.dropdown-contain-custom {
    padding-top: 43px !important;
    margin-bottom: 20px !important;
    border-bottom-left-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
}

.new-dropdown {
    display: block !important;
}

.box-on-top {
    position: relative;
    z-index: 50;
}

.dropdown.is-active .dropdown-menu,
.dropdown.is-hoverable:hover .dropdown-menu {
    display: block;
    //   margin-top: -43px !important;
}

.bundel-subhedding {
    padding: 6px 16px;
}

.itemdiscription.active{
      color: #3199ee;
}

.reportdiscription.active{
      color: #3199ee;
}
</style>
  