const dummyData = [
    {
      "_id": "634d108053c4d7395cd62e6a",
      "name": "Graham Herman",
      "gender": "male",
      "company": "UNIA",
      "email": "grahamherman@unia.com",
      "phone": "+1 (854) 587-3445",
      "address": "422 Troutman Street, Munjor, Nebraska, 862",
      "status": "",
      "registered": "2019-02-26T07:08:28 -06:-30"
    },
    {
      "_id": "634d10805c7e6299add8cf35",
      "name": "Letha Kennedy",
      "gender": "female",
      "company": "PEARLESSA",
      "email": "lethakennedy@pearlessa.com",
      "phone": "+1 (967) 419-3105",
      "address": "397 Manhattan Avenue, Hatteras, Colorado, 3761",
      "status": "",
      "registered": "2017-04-08T04:24:44 -06:-30"
    },
    {
      "_id": "634d1080d9fd6e9db864b6f9",
      "name": "Mavis Chase",
      "gender": "female",
      "company": "POLARIA",
      "email": "mavischase@polaria.com",
      "phone": "+1 (826) 444-2593",
      "address": "954 Stryker Court, Rosine, Arizona, 4230",
      "status": "",
      "registered": "2021-01-22T07:27:05 -06:-30"
    },
    {
      "_id": "634d1080e7e92b44fb1e5657",
      "name": "Knapp Mcgee",
      "gender": "male",
      "company": "MAGMINA",
      "email": "knappmcgee@magmina.com",
      "phone": "+1 (920) 522-3042",
      "address": "878 Lyme Avenue, Cliff, Marshall Islands, 10000",
      "status": "",
      "registered": "2021-01-23T09:16:02 -06:-30"
    },
    {
      "_id": "634d10801e762b8fd96c4cfc",
      "name": "Hopkins Ball",
      "gender": "male",
      "company": "FITCORE",
      "email": "hopkinsball@fitcore.com",
      "phone": "+1 (885) 489-2013",
      "address": "648 Hope Street, Westmoreland, American Samoa, 6902",
      "status": "",
      "registered": "2018-02-05T09:49:25 -06:-30"
    },
    {
      "_id": "634d1080634a1fedba4f6813",
      "name": "Stokes Mcconnell",
      "gender": "male",
      "company": "TEMORAK",
      "email": "stokesmcconnell@temorak.com",
      "phone": "+1 (847) 503-3058",
      "address": "140 Glenwood Road, Rehrersburg, South Carolina, 8028",
      "status": "",
      "registered": "2018-12-26T10:35:21 -06:-30"
    }
  ]

  module.exports= {dummyData}