globalText = {
    okbtn: "Ok",
    action: "Action",
    titlePending: "Pending",
    titleAvailable: "Available",
    titleArchived: "Archived",
    titleHistory: "History",
    titleInfo: "Info",
    titleHome: "Home",
    titleVerify: "Verify",
    titleProfile: "Profile",
    titleFeedback: "Feedback",
    titleAbout: "About"

}

module.exports= {globalText}